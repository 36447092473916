@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');


.text-ibm {  font-family: 'IBM Plex Mono', monospace !important; }



.text-danger {
  color: #FF6766
}


// resetting
h2,
h3 {
  margin-bottom: 0 !important;
  height: fit-content;
  color: white;
}

body {
  max-height: 100vh;
  max-width: 100vw;
  color: white;
  min-height: -webkit-fill-available;
}

.h-screen {
  max-height: -webkit-fill-available;
}

button > * {
  pointer-events: none;
}

.App {
  display: flex;
  justify-content: center;
}

* {
  font-family: "IBM Plex Sans", sans-serif;
}

.App-inner {
  width: 100%;
}

.container {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  max-width: 100% !important;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}


a {
  font-family: "IBM Plex Sans", sans-serif;
  color: white;
}

// useful classes
.clickable {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.italic {
  font-style: italic;
}
.bold {
  font-style: bold;
}

.max-width-outer {
  display: flex;
  justify-content: center;
  height: calc(100vh - 56px);
  overflow: overlay;
}

.overflow-overlay {
  overflow: overlay;
}

.height-without-header {
  height: calc(100vh - 56px);
}

.max-width-inner {
  max-width: min(100vw, 1110px);
  overflow-y: overlay;
  overflow-x: hidden;
  min-width: min(100vw, 1110px);
  @media (max-width: 1128px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// background colors
.bg-base-default {
  background-color: #1f1d2b;
}
.bg-base-light {
  --tw-bg-opacity: 1;
  background-color: rgb(52, 49, 72, var(--tw-bg-opacity));
}

.bg-primary-dark {
  background-color: #4747bc;
}
.bg-primary-default {
  background-color: #6a6ae3;
}
.bg-primary-light {
  background-color: #9595ff;
}

.bg-secondary-dark {
  background-color: #0c929a;
}
.bg-secondary-default {
  background-color: #12cdd9;
}
.bg-secondary-light {
  background-color: #c9fcff;
}

.bg-gray-100 {
  background-color: #edf0f6;
}
.bg-gray-200 {
  background-color: #dce1eb;
}
.bg-gray-300 {
  background-color: #939baa;
}
.bg-gray-400 {
  background-color: #586070;
}
.bg-gray-500 {
  background-color: #333b49;
}

// text colors
.text-base-dark {
  background-color: #12101b;
}
.text-base-default {
  background-color: #1f1d2b;
}
.text-base-light {
  background-color: #343148;
}

.text-primary-dark {
  background-color: #4747bc;
}
.text-primary-light {
  background-color: #9595ff;
}

.text-secondary-dark {
  background-color: #0c929a;
}
.text-secondary-default {
  background-color: #12cdd9;
}
.text-secondary-light {
  background-color: #c9fcff;
}

.bg-gray-100 {
  background-color: #edf0f6;
}
.bg-gray-200 {
  background-color: #dce1eb;
}
.bg-gray-300 {
  background-color: #939baa;
}
.bg-gray-400 {
  background-color: #586070;
}
.bg-gray-500 {
  background-color: #333b49;
}

.underlined {
  text-decoration: underline;
}

.fullscreen {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 0;
}

h2 {
  font-weight: 500;
  font-size: 28px ;
}

h3 {
  font-size: 22px !important;
  font-weight: 500 !important;
}

#root {
  max-width: 100vw;
  overflow: hidden;
}

.bg-wordrow-1 {
  background: rgba(52, 49, 72, 0.5);
}
.overflow-overlay {
  overflow: overlay;
}

.blur {
  filter: blur(3px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


select {
  -webkit-appearance: none;
  appearance: none;
}

.gradient-background {
  background: linear-gradient(180deg, #0C929A 0%, #6A6AE3 100%);
}

.gradient-border {
  display: inline-block;
  background: linear-gradient(180deg, #0C929A 0%, #6A6AE3 100%);
  border-radius: 5px;
  width: 100%;
  padding: 1px; /* adjust as needed to create border width */
  .content {
    display: inline-block;
    background: #1C1A26; /* adjust to match your preferred background color */
    width: 100%;
    border-radius: 4px; /* adjust to be slightly smaller than the .gradient-border border-radius */
  }
}



