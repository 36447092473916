.LoginScreen {
  display: flex;
  height: 100vh;
  color: white;
}

.LoginScreen-inner {
  margin: auto;
}

.LoginScreen-inner-Google {
  margin: 25px;
  width: 250px;
}

.LoginScreen-inner-logo {
  margin: 25px;
  width: 125px;
}

.LoginScreen-inner-subtitle {
  font-weight: 500;
}
