.SquareButton-error {
  background-color: #cf4c54;
  &:hover {
    background-color: #b4272f;
  }
}
.SquareButton-primary-default {
  background-color: #6a6ae3;
  &:hover {
    background-color: #4747bc;
  }
  margin-right: 0;
}


  .SquareButton-innerText {
    text-align: center;
    font-size: 16px;
}
