@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pacifico&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.fullscreen-option {
  font-size: 18px;
  font-weight: 600 !important;
}

h1 {
  @media (min-width: 644px) {
    line-height: 65px !important;
  }
}

// teaser maximum width outer/inner
.tmwo {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  .tmwi {
    max-width: 1050px;
    @media (max-width: 1077px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    width: 100%;
  }
}




.TeaserSite-container {

h4 {
  font-size: 24px !important;
  font-weight: normal;
  @media (max-width: 600px) {
    font-size: 18px !important;
  }
}

  p {
    font-size: 18px;
    font-weight: 400;
  }
  h1, h2, h3, h4 {
    //styleName: Headers/H2;
  font-family: Inter;
  font-size: 48px;
  line-height: 45px;
  }
  width: 100%;
  .TeaserSite {
    width: 100%;
  }
  h2 {
    font-size: 40px;
    @media (max-width: 600px) {
      font-size: 30px !important;
    }
    font-style: normal;
    font-weight: 700;
  }
  h3 {
    font-family: IBM Plex Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
  }
  font-family: IBM Plex Sans;

  p {
    // color: #bfbbcd;
    //styleName: Body/Body reg;
    font-style: normal;
    letter-spacing: 0em;
  }
  .TeaserHeader {
    width: 100%;
    overflow-x: visible;
    div > p {
      pointer-events: none;
    }
    p {
      // color: white;
    }
  }
}

#FirstSection-container {
  background-color: #090613;
  width: 100%;
  max-height: 70vh;
  line-height: 1.1;
  .FirstSection {
    padding: calc(56px + 16%) 0 16% 0;
  }
  .TeaserLogo {
    display: flex;
    justify-content: center;
    width: 100%;
    .TeaserLogo-img {
      height: 64px;
      width: 64px;
      margin-bottom: 41px;
    }
  }
  .FirstSection-title {
    font-size: calc(min(70px, 9.5vw));
    font-weight: 700;
    text-align: center;
    color: white;
    max-width: 994px;
  }
}

.TeaserHeader {
  z-index: 5;
  .Header-earlyAccessButton {
    height: 34px;
    align-items: center;
    display: flex;
    border-radius: 8px;
  }

  a {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .Header-earlyAccessButton,
  .TeaserHeader-inner-navigation-button,
  a {
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 22px;
    // color: #dce1eb;
    p {
      font-family: "IBM Plex Sans", sans-serif !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 22px;
      // color: #dce1eb;
    }

    text-align: center;
    .TeaserHeader-inner-navigation-button-textSelected {
      color: #6a6ae3;
    }
  }
  position: fixed;
}

.MeetKaardsSection-container {
  background: #33325F;
  .MeetKaardsSection {
    .MeetKaardsSection-description {
      padding: 24px 0;
    }
    .MeetKaardsSection-languageInfo {
      font-family: IBM Plex Mono;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 24px;
    }
    padding: 80px 0px 73px 0px;
    @media (max-width: 600px) {
      padding: 32px 0 70px 0;
    }
    .MeetKaardsSection-button {
      max-width: 300px;
      font-family: IBM Plex Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
}

.HowItWorksSection-container {
  padding-top: 180px;
  @media (max-width: 600px) {
    // padding: 32px 0 70px 0;
  }
  background: #090613;
  #HowItWorksSection {
    justify-content: center;
    .HowItWorksSection-seeProgress-container {
      display: flex;
      justify-content: center;
      .HowItWorksSection-seeProgress {
        padding: 140px 0;
        z-index: 100;
        @media (max-width: 600px) {
          padding: 90px 0 25px 0;
        }
        max-width: 600px;
        text-align: center;
        span {
          font-family: "Great Vibes", cursive;
          font-size: 75px;
        }
      }
    }
  }
}

.WhyBuildSection-container {
  background: #090613;
  .WhyBuildSection {
    .WhyBuildSection-byLearners {
      margin-top: 24px;
      @media (max-width: 600px) {
        font-size: 18px !important;
      }
    }
    h2 {
      margin-top: 140px;
      @media (max-width: 600px) {
        margin-top: 32px;
      }
    }
    .WhyBuildSection-InfoBoxHolder {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 700px) {
        justify-content: center;
      }

      .InfoBox-container {
        padding: 40px;
        @media (max-width: 600px) {
          padding: 26px !important;
        }
        width: 49%;
        border-radius: 12px;
        // min-width: 300px;
        @media (max-width: 700px) {
          width: 100%;
        }

        .InfoBox {
          .InfoBox-title {
            font-size: 24px;
            @media (max-width: 600px) {
              font-size: 18px !important;
            }
            font-style: normal;
            color: white;
            // font-weight: 600;
            margin-bottom: 24px !important;
          }
          .InfoBox-content {
            font-size: 18px;
            color: white;
            font-style: normal;
            font-weight: 400;
            @media (max-width: 600px) {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }
}

.MeetTeamSection-container {
  background: #090613;
  #MeetTeamSection {
    h2 {
      padding-top: 100px;
      padding-bottom: 40px;
      @media (max-width: 610px) {
        padding-top: 32px;
      padding-bottom: 32px;
      }
    }
    .MeetTeamSection-TeamMemberHolder {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 610px) {
        justify-content: center;
      }
      padding-bottom: 140px;
    }
    .TeamMember-container {
      text-align: center;
      max-width: 443px;
      min-width: 280px;
      width: 48%;
      padding: 32px;
      border-radius: 20px;
      @media (max-width: 915px) {
        margin-bottom: 20px;
        width: 100%;
        max-width: 100vw;
      }
      .TeamMember {
        .TeamMember-image {
          img {
            width: 142px;
            height: 142px;
          }
        }
        .TeamMember-name {
          font-family: IBM Plex Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          color: white;
          text-align: center;
          padding-top: 32px;
        }
        .TeamMember-description {
          font-family: IBM Plex Mono;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          color: #05ACA2;
          padding-top: 24px;
        }
        .TeamMember-location-container {
          display: flex;
          justify-content: center;
          .TeamMember-location {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            border: 1px solid #33325f;
            width: fit-content;
            border-radius: 8px;
            margin-top: 24px;
            .outer-icon,
            inner-icon {
              width: 24px;
              height: 24px;
            }
            p {
              padding-left: 10px;
              font-family: IBM Plex Mono;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.bg-teaser-gray {
  background-color: #1c1a26;
}

.LanguageSelectionSection-container {
  height: fit-content;
  width: 100%;
  text-align: left;
  display: flex;
  padding: 66px 0;
  background-color: #080613;
  
  @media (max-width: 644px) {
    padding: 32px 0;
  }
  h2 {
    width: 100%;
  }
  .LanguageSelectionSection {
    
    // padding:60px;
    .LanguageSelectionSection-whichLanguagesTitle {
      margin-top: 60px;
    }
    .LanguageSelectionSection-submitButton {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      // width: 120px;
      height: 49px;
      @media (max-width: 600px) {
        width: 100% !important;
        margin-top: 12px;
      }
      
    }

    .LanguageSelectionSection-submittedButton {
      background-color: #05ACA2;
      cursor: auto;
    }
    .LanguageSelectionSection-enterEmailTitle {
      padding: 48px 0 24px 0;
    }
  }
  input {
    ::placeholder {
      color: #586070;
    }
    // border: none;
    background-color: white;
    border-radius: 3px;
    font-weight: normal;
    font-size: 14px;
    // max-width: 392px;
    @media (max-width: 600px) {
      width: 100%;
    }
    padding: 9px 9px 9px 9px;
    color: #333b49;
    height: 49px;
    &:focus {
      outline: none;
    }
  }

  .language-button {
    height: 42px;
    min-width: 73px;
    margin: 10px 0;
    margin-right: 16px;
    left: 0px;
    top: 0px;
    border-radius: 8px;
    padding: 10px;
    background: #33325f;
    border: 0;
    color: white;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    .language-button-inner {
      display: flex;
      justify-content: center;
      p {
        color: white;
      }
    }
  }
  .language-button.language-button-selected {
    background: #05ACA2;
    p {
      margin-left: 10px;
    }
    img {
      height: 11px;
      width: 15px;
    }
  }
}

.Header-inner-navigation-left {
  display: flex;
}

.Header-inner-navigation-right {
  display: flex;
  justify-content: flex-end;
}

.d-none-sm {
  display: flex;
  @media (max-width: 644px) {
    display: none !important;
  }
}

.d-sm {
  display: flex;
  @media (min-width: 644px) {
    display: none !important;
  }
}

.TeaserHeader-inner {
  position: relative;
  overflow: visible;
  .Dropdown-k-options {
    position: absolute;
    top: 30px;
    width: fit-content;

    .Dropdown-k-options-option {
      display: flex;
      justify-content: flex-start;
      padding: 10px;
      text-align: left;
      font-weight: 800;
      width: 120px;
    }
  }
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.overflow-visible {
  overflow: visible;
}

.mw-50 {
  max-width: calc(min(50%, 839px));
}

.mw-40 {
  max-width: 40%;
}

.shadow-white {
  -webkit-box-shadow: 0px 0px 22px 5px #1d1a2613;
  box-shadow: 0px 0px 22px 5px #1d1a2613;
}

.bg-lindie-black {
  background: #090613;
}

@media (min-width:640px) and (max-width:900px) {
  .side-images-desktop {
    top: 50px !important;
    width: 500px !important;
    max-width: 70% !important;
  }
}

@media (max-width:765px) {
  .HowItWorksSection-container {
    padding-top: 32px !important;
  }
}

@media (min-width: 915px) {
  .team-member-holder {
    flex-direction: row !important;
  }
}